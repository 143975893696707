import { ComponentType } from 'react';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import type { BlockFragment } from '@orgnc/core/lib/wordpress/api/fragments/blocksFragment';
import {
  IAcfFullWidthCallToActionBlock,
  IAcfFullWidthCallToActionBlockCta,
} from '@orgnc/core/generated/graphql-operations';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { generateFastlyImageURL, imageLoaderFastly } from '@/lib/utilities';
import Image from 'next/image';
import { SocialItem, SocialType } from '@orgnc/core/components/atoms';

/**
 * Transforms CSS alignment values from traditional to flexbox format
 * @param {string} value - The alignment value to transform
 * @returns {string} - The transformed value
 */
function transformAlignment(value: string): string {
  if (!value) {
    return value;
  }

  switch (value.trim().toLowerCase()) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return value;
  }
}
const FullWidthCallToActionComponent: ComponentType<RenderBlockProps> = ({
  block,
  frequency,
  area,
}) => {
  const acfFullWidthCallToActionBlock =
    block as unknown as BlockFragment<IAcfFullWidthCallToActionBlock>;

  const fullWidthCallToAction =
    acfFullWidthCallToActionBlock.fullWidthCallToAction as unknown as IAcfFullWidthCallToActionBlockCta;
  const {
    heading,
    subheading,
    cta_image: ctaImage,
    background_image: backgroundImage,
    cta_rows: ctaRows,
    cta_alignment: ctaAlignment,
    cta_buttons_rounded: isBtnsRounded = 1,
    cta_text_uppercase: isTextUppercase = 1,
    cta_text_color: ctaTextColor = 'brand.text',
    cta_buttons_bg_color: ctaButtonsBgColor,
    cta_buttons_text_color: ctaButtonsTextColor,
    background_color: backgroundColor,
    cta_buttons_outline_color: ctaButtonsOutlineColor,
    cta_buttons_style: ctaButtonsStyle,
  } = fullWidthCallToAction;
  return (
    <Box
      data-og-block-area={area}
      data-og-block-nth={frequency}
      data-og-block-type="acf/full-width-call-to-action"
      sx={{
        position: 'relative',
        minHeight: {
          md: '315px',
          sm: '380px',
          xs: '420px',
        },
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          backgroundImage: backgroundImage?.sourceUrl
            ? `url(${generateFastlyImageURL(backgroundImage.sourceUrl)})`
            : 'none',
          backgroundColor: backgroundImage?.sourceUrl
            ? undefined
            : backgroundColor,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 50%',
          top: 0,
          left: 0,
          right: 0,
          minHeight: '315px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '1440px',
            px: '30px',
            py: '50px',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: transformAlignment(ctaAlignment || 'left'),
          }}
        >
          {!heading && ctaImage?.sourceUrl && (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                maxWidth: {
                  sm: '85%',
                  md: '40%',
                },
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Image
                alt={String(ctaImage.altText || '')}
                src={generateFastlyImageURL(ctaImage.sourceUrl)}
                width={ctaImage.mediaDetails?.width || 500}
                height={ctaImage.mediaDetails?.height || 300}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
                priority
                loader={imageLoaderFastly}
              />
            </Box>
          )}
          {!ctaImage?.sourceUrl && heading && (
            <Typography
              variant={'h4'}
              sx={{
                color: ctaTextColor,
                fontSize: {
                  sm: '28px',
                  md: '38px',
                },
                fontWeight: 400,
              }}
            >
              {heading}
            </Typography>
          )}
          {!ctaImage?.sourceUrl && subheading && (
            <Typography
              variant={'body1'}
              sx={{
                color: ctaTextColor,
                fontSize: {
                  sm: '16px',
                  md: '18px',
                },
                fontWeight: 400,
                mt: 'auto',
              }}
            >
              {subheading}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '.8rem',
              mt: {
                sm: 3,
                xs: 4,
                md: 'auto',
              },
            }}
          >
            {ctaRows &&
              ctaRows?.length > 0 &&
              ctaRows.map((cta_row, index) => {
                const ctaItems = cta_row?.cta_items;
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      gap: '.5rem',
                      flexWrap: 'wrap',
                      justifyContent: transformAlignment(
                        ctaAlignment || 'left'
                      ),
                    }}
                  >
                    {ctaItems &&
                      ctaItems.map((cta_item, itemIndex) => {
                        const ctaItemText = cta_item?.cta_item_text;
                        const ctaItemUrl = cta_item?.cta_item_url;
                        const ctaItemIcon = cta_item?.cta_item_icon;
                        return (
                          <Box
                            key={itemIndex}
                            component="a"
                            href={ctaItemUrl || '#'}
                            target="_blank"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor:
                                ctaButtonsBgColor || 'brand.header',
                              border:
                                ctaButtonsStyle === 'outline'
                                  ? `2px solid ${
                                      ctaButtonsOutlineColor ||
                                      ctaButtonsBgColor ||
                                      'brand.header'
                                    }`
                                  : 'none',
                              borderRadius: isBtnsRounded ? 99 : 0,
                              px: ctaItemText ? 3 : 1.5,
                              py: 1.5,
                              textTransform: isTextUppercase
                                ? 'uppercase'
                                : 'none',
                              letterSpacing: '0.01em',
                              fontWeight: 600,
                              fontSize: '15px',
                              color: ctaButtonsTextColor || 'white',
                              textDecoration: 'unset',
                              transition: '.3s ease',
                              '&:hover': {
                                filter: 'brightness(1.2)',
                              },
                            }}
                          >
                            {!ctaItemText && ctaItemIcon && (
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <SocialItem
                                  size={'small'}
                                  color={ctaButtonsTextColor || 'brand.text'}
                                  type={ctaItemIcon.toLowerCase() as SocialType}
                                  variant="outlined"
                                  openNewTab
                                />
                              </Box>
                            )}
                            {!ctaItemIcon && ctaItemText && ctaItemText}
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FullWidthCallToActionComponent;
